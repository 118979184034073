<script setup lang="ts">
type _VTI_TYPE_UnwrapNestedRefs = T extends Ref ? T : UnwrapRefSimple<T>
interface Props {
        timeActivity: _VTI_TYPE_UnwrapNestedRefs;
    }
    
    import TimeActivity from '~~/app/planning/models/TimeActivity'
    
    const props = defineProps({
  timeActivity: null
});
    const { t } = useTranslation();
</script>

<template>
    <div
        class="flex items-center px-4 py-2 space-x-4 transition-all bg-white border border-gray-200 rounded cursor-pointer hover:border-gray-50 hover:bg-neutral-50 active:bg-neutral-100 dark:bg-neutral-800 dark:border-gray-900"
        @click="timeActivity.preview()"
        @contextmenu="timeActivity.openContextMenu($event)"
    >
        <AuthUserAvatar :user="timeActivity?.user" />
        <div class="flex-1">
            <span class="text-sm dark:text-gray-300 lg:text-base">
                {{ timeActivity.title }}
            </span>
            <span
                v-if="timeActivity.worksite"
                class="block -mt-2"
            >
                <span class="text-xs text-gray-500 dark:text-gray-400 lg:text-sm">
                    {{ timeActivity.worksite.title }}
                    <span v-if="timeActivity.worksitePhase"> - {{ timeActivity.worksitePhase.title }} </span>
                </span>
            </span>
        </div>
        <template v-if="timeActivity.invoicedAt">
            <template v-if="timeActivity.invoice">
                <InvoicingInvoiceStatusBadge
                    :invoice="timeActivity.invoice"
                    size="px-2 py-1 text-[10px] min-w-[70px]"
                    :custom-label="timeActivity.invoice.numberOrDraft"
                />
            </template>
            <template v-else>
                <span :data-tooltip="$t('misc.marked_as_invoiced_manually')">
                    <i class="text-gray-800 fa-regular fa-check-circle"></i>
                </span>
            </template>
        </template>
        <div class="flex flex-col items-end min-w-[120px]">
            <div class="flex items-center space-x-2">
                <span class="text-xs text-gray-600 dark:text-gray-400 lg:text-sm">
                    {{ timeActivity.startsAt?.toShortTimeString() }} -
                    {{ timeActivity.endsAtOrNow.toShortTimeString() }}
                </span>
            </div>
            <PlanningTimeActivityItemTimer :time-activity="timeActivity" />
        </div>
    </div>
</template>
